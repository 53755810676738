import lazyload from "./block/lazyload"
import renderBlock from "./block/renderBlock.js"

const b = "flex-block"
const dotb = `.${b}`

const onOrientationChange = e => {
  const $flexBlocks = $(dotb)
  $flexBlocks.each((index, block) => {
    const $el = $(block)
    const id = $el.parents(".flo-block").attr("data-id")
    renderBlock($el, id, screen) //eslint-disable-line
  })

  lazyload()
}

const handleVideoClick = e => {
  let $element = $(e.target).parents(`${dotb}__element`)
  let $iframe = $element.find("iframe")
  let $videoFile = $element.find("video")

  let $videoToStart = null
  if ($iframe.length) {
    $videoToStart = $iframe
  } else if ($videoFile.length) {
    $videoToStart = $videoFile
  }

  if (
    $videoToStart &&
    $videoToStart.attr("data-src") &&
    !$videoToStart.attr("src")
  ) {
    $(e.target).fadeOut("250", () => {
      $(e.target).remove()
      $videoToStart.attr("src", $videoToStart.attr("data-src"))
      $videoToStart.removeAttr("data-src")
      $element.addClass(`${b}__video-started`)
    })
  }
}

export default function registerListeners() {
  window.addEventListener("orientationchange", onOrientationChange, false)

  $("body").on(
    "click",
    `${dotb}__video--has-image ${dotb}__play-button`,
    handleVideoClick
  )

  $("body").on("click", ".flex-no-controls", function() {
    let vid = this
    vid.paused ? vid.play() : vid.pause()
  })
}
