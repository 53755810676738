import {renderVideos, renderParallax} from "./utils"

export default function renderBlock($el, id, screen = null) {
  const dotb = ".flex-block"

  // flexWidth is the window.innerWidth value registered before document ready
  const screenWidth = screen ? screen.width : window.flexWidth

  let viewportToRender, parentSel
  if (screenWidth > 767) {
    viewportToRender = "desktop"
    parentSel = `${dotb}__main-container`
  } else {
    viewportToRender = "mobile"
    parentSel = `${dotb}__mobile-container`
  }

  const renderedContent =
    window.flexBlocksList &&
    window.flexBlocksList[id] &&
    window.flexBlocksList[id][viewportToRender]
      ? window.flexBlocksList[id][viewportToRender]
      : null
  if (renderedContent) $el.html(renderedContent)

  const $parent = $el.find(parentSel)
  if (screenWidth > 767) renderParallax($parent)
  renderVideos($parent)
}
